import React from 'react';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import { Columns, ColumnItem } from '@uc-common/b-columns';
import {
  LeadForm,
  LeadFormCheckboxField,
  LeadFormFieldKindEnum,
  LeadFormPrivacyPolicyLink,
  LeadFormTextField,
} from '@uc-common/b-leadform';
import { FORM_ID_ENUM } from '@uc-common/use-lead-form';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { Box } from 'rebass/styled-components';

import { Footer } from '../../../components/Footer';
import { CommonLayout } from '../../../components/Layout/CommonLayout';
import { Link } from '../../../components/Link';
import { Navbar, NavbarModesEnum } from '../../../components/Navbar';

export const frontmatter = {
  title: 'Uploadcare for startups',
  description:
    'Get full access to our Scale plan with up to 70% discount, including a ready-made infrastructure for uploading, processing, and delivering media content, and 800 GB of traffic.',
  noindex: true,
};

const Apply = ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <BLayout header={<Navbar mode={NavbarModesEnum.PPC} />} footer={<Footer />}>
        <Head
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
          }}
          variant={HeadVariantsEnum.V1}
          title="Uploadcare for startups"
          description="Get 6-month access to&nbsp;the Uploadcare Scale plan at&nbsp;a&nbsp;70% discount and enjoy a&nbsp;full-featured infrastructure with 800&nbsp;GB of&nbsp;traffic included."
        />
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
          }}
        >
          <LeadForm
            id={FORM_ID_ENUM.STARTUP_DISCOUNT}
            title="See if you’re eligible:"
            illustrationSrc="https://ucarecdn.com/c90062c8-08d2-41fd-a817-18d00732499b/BenefitsVisual12803.svg"
            formTitle="Apply now!"
            formCta="Apply now"
            description=""
          >
            <LeadFormTextField kind={LeadFormFieldKindEnum.NAME} placeholder="Full name" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.WEBSITE} placeholder="Company website" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.EMAIL} placeholder="Business email" />
            <LeadFormTextField
              kind={LeadFormFieldKindEnum.PHONE}
              required={false}
              placeholder="Phone"
            />
            <Box
              as={LeadFormTextField}
              forwardedAs="textarea"
              kind={LeadFormFieldKindEnum.CUSTOM}
              required={false}
              name="Description"
              placeholder="Tell&nbsp;us about your startup: what product do&nbsp;you offer and for whom? If&nbsp;you came from an&nbsp;accelerator, please specify which one."
              sx={{
                resize: 'vertical',
                minHeight: '150px',
              }}
            />
            <LeadFormCheckboxField
              kind={LeadFormFieldKindEnum.PRIVACY_POLICY_CHECKBOX}
              label={
                <>
                  I agree to the{' '}
                  <LeadFormPrivacyPolicyLink>Privacy Policy</LeadFormPrivacyPolicyLink>{' '}
                </>
              }
            />
          </LeadForm>
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_XL,
          }}
        >
          <Head variant={HeadVariantsEnum.V2} title="Join the fastest-growing startups out there" />
          <Columns sx={{ paddingTop: PaddingEnum.VERTICAL_M }}>
            <ColumnItem
              title="What’s included?"
              description="The Uploadcare Scale plan includes a&nbsp;ready-made infrastructure for uploading, processing, and delivering media content, and 800&nbsp;GB of&nbsp;bandwidth usage monthly."
            />
            <ColumnItem
              title="How much does it&nbsp;cost?"
              description="The regular price for the Scale plan is&nbsp;$385/mo. As&nbsp;an&nbsp;eligible startup, you&rsquo;ll be&nbsp;able to&nbsp;use all its capabilities at&nbsp;a&nbsp;70% discount which will be&nbsp;valid for&nbsp;six months."
            />
            <ColumnItem
              title="What’s next?"
              description="Upon expiration of&nbsp;the discounted period, you can stay on&nbsp;this plan, switch to&nbsp;another one, or&nbsp;choose to&nbsp;stop using Uploadcare without any penalties."
            />
          </Columns>
        </Section>
        <Section
          sx={{
            py: PaddingEnum.VERTICAL_XL,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V2}
            title="Accelerator?"
            description="We&rsquo;d love to&nbsp;partner and provide exclusive terms for your mentees to&nbsp;help them launch their product and enter the market faster than their competitors."
            cta={
              <Button as={Link} to="mailto:partnerships@uploadcare.com" type={ButtonTypeEnum.DARK}>
                Let’s team up
              </Button>
            }
          />
        </Section>
      </BLayout>
    </CommonLayout>
  );
};

export default Apply;
